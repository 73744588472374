/* eslint-disable no-octal-escape */
/* eslint-disable no-unused-vars */
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import iconeLogoutGreen from '../../../images/solicite-sua-coleta/002-logout-green.svg'
// Assets - green
import iconeUsuarioGreen from '../../../images/solicite-sua-coleta/Group-green.svg'
import { putItem } from '../../../services/api'
import { getUser, isBrowser, logout } from '../../../services/auth'
import CentroColetaJson from '../../../utils/centros.json'

// services
import { findAddress } from '../services/coleta-service'

// controllers
import {
  validateForm
} from '../controllers/coleta-oleo-controller'

// Options
const AvailableServices = [
  { name: 'gestao-de-residuos', formattedName: 'Gestão de Resíduos' },
  { name: 'coleta-de-oleo', formattedName: 'Coleta de Óleo' }
]

const Coleta = (props) => {
  const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
  const [ufs, setUfs] = useState([])
  const [districts, setDistricts] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const user = getUser().attributes
  user['custom:cnpj'] = user['custom:cnpj'].replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

  const initialForm = {
    cnpjUnidade: '',
    cidade: '',
    estado: '',
    telefone: '',
    nome: '',
    email: '',
    cep: '',
    servico: 'coleta-de-oleo',
    cnpj: user['custom:cnpj'],
    userEmail: user.email,
    userName: user.name,
    userPhone: user.phone_number,
    userAddress: user.address,
    collectAddress: '',
    numberAddress: '',
    oilContaminated: ''
  }
  const [formState, setFormState] = useState(initialForm)

  useEffect(() => {
    const keys = Object.keys(CentroColetaJson)
    setUfs(keys.map(key => ({ concat: CentroColetaJson[key].concat, id: key })))
  }, [])

  useEffect(() => {
    console.log('Form state', formState)
  }, [formState])
  const setInput = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const changeService = (route) => {
    navigate(`/area-cliente/solicite-sua-coleta/${route}/`)
    resetValue()
  }

  const resetValue = () => {
    setFormState(initialForm)
    document.getElementsByName('estado')[0].value = '-- Selecione --'
  }

  function handleStateSelected (e) {
    const center = CentroColetaJson[e.target.value]
    setDistricts(center.cidade)
    setFormState({ ...formState, estado: e.target.value })
  }

  async function handleCep (e) {
    const cepInput = e.target.value
    if (!cepInput.match(/\d{5}-\d{3}/)) return
    const address = await findAddress(cepInput)
    setFormState({
      ...formState,
      cep: address.cep,
      estado: address.uf || '',
      cidade: address.localidade || '',
      collectAddress: address.logradouro || ''
    })
  }

  const HandleSolicitation = async () => {
    setDisableSubmitButton(true)
    setSuccessMessage(false)
    const { canSubmit, message } = validateForm(formState)
    console.log(canSubmit, message)
    if (!canSubmit) {
      setErrorMessage(message)
      setDisableSubmitButton(false)
      return
    }
    try {
      const result = await putItem(formState)
      setErrorMessage(false)
      setSuccessMessage(<>Solicitação de Coleta Enviada com sucesso!</>)
      resetValue()
    } catch (err) {
      console.log(err)
      setSuccessMessage(false)
      setErrorMessage(<>Não foi possível concluir a solicitação. <br/> Por favor, verifique os dados e tente novamente! </>)
    } finally {
      setDisableSubmitButton(false)
    }
  }

  return (
    <div className={'bg-solicite'}>
      <div className="container containerMobile pr-0 pl-0">
        <div className="row m-0">
          <div className={'col-lg-5 bg-lwart-blue p-0'}>
            <div className="container">
              <div className="row py-5 px-4">
                <div className="col-lg-12 d-flex justify-content-end">
                  <button className="bg-transparent border-0 d-lg-none d-flex  align-items-center"
                    onClick={() => logout(() => isBrowser() && navigate(localStorage.getItem('originPath')))}
                  >
                    <p className="text-white mb-0 above">Sair&nbsp;</p>
                    <img src={iconeLogoutGreen} alt="" />
                  </button>
                </div>
                <div className="col-lg-2 p-0">
                  <div className="d-flex justify-content-lg-end justify-content-center">
                    <img src={iconeUsuarioGreen} alt="imagem do usuario" />
                  </div>
                </div>
                <div className="col-lg-8 pr-lg-2 pl-lg-2 pr-0 pl-0">
                  <h2 className="text-white text-lg-left text-center">{user.name}</h2>
                  <p className="text-white m-0 line-height-normal text-lg-left text-center font-size-125">CNPJ: {user['custom:cnpj']}</p>
                  <p className="text-white m-0 line-height-normal text-lg-left text-center font-size-125">{user.address}</p>
                  <Link to="/area-cliente/dados-cadastrais" className="btn btn-buttonGreen w-100 mt-3 font-Spinnaker"
                    onClick={() => isBrowser() && window.localStorage.setItem('prevRoute', formState.servico)}
                  >Dados Cadastrais</Link>
                  <Link to="/area-cliente/historico-de-coleta" className="btn btn-buttonGreen w-100 mt-3 font-Spinnaker">Histórico de Coleta</Link>
                  <Link
                    to={`/area-cliente/solicite-sua-coleta/${formState.servico}`}
                    className="btn btn-buttonGreen mt-3 w-100 mb-lg-0 mb-3 font-Spinnaker"
                    onClick={() => {
                      setTimeout(() => {
                        const el = document.getElementById('maps-section')
                        el.scrollIntoView({ block: 'start', behavior: 'smooth' })
                      }, 500)
                    }}
                  >
                    Licenças
                  </Link>
                  <Link
                    to="/area-cliente/pesquisa-de-satisfacao"
                    className="btn btn-buttonGreen3 w-100 mt-3 mb-3 font-Spinnaker text-lwart-blue">
                    Responda a Nossa<br/> Pesquisa de Satisfação
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-lg-7 bg-lwart-lightblue2 p-0'}>
            <div className="container">
              <div className="col-lg-12 position-absolute justify-content-end d-lg-flex pr-5 d-none">
                <button className="bg-transparent border-0 d-flex pt-4"
                  onClick={() => logout(() => isBrowser() && navigate(localStorage.getItem('originPath')))}
                >
                  <p className="text-white above">Sair&nbsp;</p>
                  <img src={iconeLogoutGreen} alt="" />
                </button>
              </div>
              <div className="row coleta-header">
                <div className="col-lg-12 p-lg-5 pt-5 pb-5 pr-4 pl-4">
                  <form className={'form-coleta-blue p-0 w-100 mw-100'}>
                    <div className="d-flex justify-content-center">
                      <div className="row w-100 no-gutters">
                        <div className="col-lg-12 d-flex align-items-center">
                          <h4 className="text-white">Solicite aqui sua {'coleta'}</h4>
                        </div>
                        <div className="w-100 border-bottom-coleta" />
                        <div className="col-lg-12 w-100 mb-2">
                          <label className="mb-0 font-Spinnaker text-white pt-lg-2 pb-lg-2">Serviço</label>
                          <select value={formState.servico} className="W-100" onChange={(e) => changeService(e.target.value)}>
                            {
                              AvailableServices.map((servico, index) => (
                                <option
                                  defaultValue
                                  value={servico.name}
                                  key={index}
                                >{servico.formattedName}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-lg-5">
                        <label className="text-white font-Spinnaker">Cep</label>
                        <InputMask
                          mask="99999-999"
                          className="w-100 mb-3"
                          name="cep"
                          type="text"
                          value={formState.cep}
                          onChange={(e) => {
                            setInput(e)
                            handleCep(e)
                          }}
                        />
                      </div>
                      <div className="col-lg-7">
                        <label className="text-white font-Spinnaker">Cidade</label>
                        <InputMask
                          className="w-100 mb-3"
                          name="cidade"
                          type="text"
                          value={formState.cidade}
                          onChange={(e) => setInput(e)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="text-white font-Spinnaker">Endereço da Coleta</label>
                        <input value={formState.collectAddress} name="collectAddress" type="text" className="w-100 mb-3" onChange={(e) => setInput(e)}/>
                      </div>
                      <div className="col-lg-3">
                        <label className="text-white font-Spinnaker">Número</label>
                        <input value={formState.numberAddress} name="numberAddress" type="number" className="w-100 mb-3" onChange={(e) => setInput(e)}/>
                      </div>
                      <div className="col-lg-3">
                        <label className="text-white font-Spinnaker">Estado</label>
                        <select className="mb-4" name="estado" onChange={(e) => handleStateSelected(e)}>
                          <option defaultValue> { formState.estado || 'UF' } </option>
                          {
                            ufs.map((uf, index) => {
                              return (
                                <option value={uf.id} key={uf.id}>
                                  {uf.concat}
                                </option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className="col-lg-5">
                        <label className="text-white font-Spinnaker">CNPJ</label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          className="w-100 mb-3"
                          name="cnpjUnidade"
                          type="text"
                          value={formState.cnpjUnidade}
                          onChange={(e) => setInput(e)}
                        />
                      </div>
                      <div className="col-lg-7">
                        <label className="text-white font-Spinnaker">Nome do Responsável</label>
                        <input value={formState.nome} name="nome" type="text" className="w-100 mb-3" onChange={(e) => setInput(e)}/>
                      </div>
                      <div className={'col-lg-12'}>
                        <label className="text-white font-Spinnaker">E-mail</label>
                        <input value={formState.email} name="email" type="text" className="w-100 mb-3" onChange={(e) => setInput(e)}/>
                      </div>
                      <div className="col-lg-5">
                        <label className="text-white font-Spinnaker">Volume Disponível (Litros)</label>
                        <input value={formState.qtdLitros} type="number" className="w-100 mb-3" name="qtdLitros" onChange={(e) => setInput(e)}/>
                      </div>
                      <div className="col-lg-7">
                        <label className="text-white font-Spinnaker">Telefone</label>
                        <InputMask
                          mask={telMask}
                          maskPlaceholder={null}
                          className="mb-3 w-100"
                          name="telefone"
                          type="text"
                          value={formState.telefone}
                          onChange={(e) => {
                            setInput(e)
                            if (e.target.value.match(/\d{2}\s\d{4}-\d{5}/)) {
                              setTelMask([/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])
                            } else {
                              setTelMask([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="text-white font-Spinnaker">Esse óleo está contaminado com água? Ou algum tipo de resíduo?</label>
                        <input value={formState.oilContaminated} name="oilContaminated" type="text" className="w-100 mb-3" onChange={(e) => setInput(e)}/>
                      </div>
                      {
                        !!errorMessage && (
                          <div className="col-lg-12">
                            <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-2 px-4 my-3 rounded">
                              <p className="bg-danger shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                                style={{ width: '25px', height: '25px' }} >!</p>
                              <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                                {errorMessage}
                              </p>
                            </div>
                          </div>
                        )
                      }
                      {
                        !!successMessage && (
                          <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                              <p
                                className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                                style={{ width: '45px', height: '45px' }} >✔</p>
                              <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                                {successMessage}
                              </p>
                            </div>
                          </div>
                        )
                      }
                      {
                        !successMessage && (
                          <div className="col-lg-5">
                            <button
                              type="button"
                              className="text-white btn-entrar btn w-100 py-2 mb-3 font-Spinnaker"
                              disabled={disableSubmitButton}
                              onClick={() => HandleSolicitation()}
                            >Confirmar</button>
                          </div>
                        )
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Coleta
