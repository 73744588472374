import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Pagina from '../Pagina'
import { changePassword } from '../../services/auth'

const TrocarSenha = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [formState, setFormState] = useState()
  const data = useStaticQuery(
    graphql`{
  pagina: contentfulPagina(path: {eq: "/trocar-senha/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  img: file(relativePath: {eq: "man.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 700, height: 840, layout: CONSTRAINED)
    }
  }
}
`
  )

  const setInput = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleValidation = () => {
    if (formState && Object.keys(formState).length < 3) {
      setErrorMessage('Todos os Campos São Obrigatórios!')
    } else if (formState.newPassword !== formState.confNewPassword) {
      setErrorMessage('As Senhas digitadas não correspondem!')
    } else {
      return true
    }
  }

  const confirmChangePassword = () => {
    if (handleValidation()) {
      changePassword(formState.oldPassword, formState.newPassword)
        .then(res => {
          if (res === 200) {
            setErrorMessage(false)
            setSuccessMessage(<>Senha Alterada com sucesso! <br/> Redirecionando... </>)
            setTimeout(() => {
              navigate('../dados-cadastrais/')
            }, 3000)
          } else {
            setErrorMessage('Não foi possível alterar a senha. Tente novamente!')
          }
        })
    }
  }
  return <>
    <Pagina pagina={data.pagina} />
    <section className="container-fluid full m-0">
      <div className="row">
        <div className="col-lg-4 p-0">
          <GatsbyImage
            image={data.img.childImageSharp.gatsbyImageData}
            alt=""
            className="w-100 h-100" />
        </div>
        <div className="col-lg-8 bg-lwart-lightblue2 p-0">
          <div className="container" style={{ padding: '35px' }}>
            <p className="text-white text-uppercase font-Spinnaker font-size-125">Solicite sua Coleta</p>
            <p className="font-Spinnaker font-size-2 text-lwart-green3"><b>Trocar Senha</b></p>
            <div className="col-lg-10 p-0">
              <form className="form-coleta p-0 w-100 mw-100">
                <div className="row">
                  <div className="col-lg-10">
                    <label className="text-white font-Spinnaker">
                      Informe sua senha atual
                      <input name="oldPassword" className="mb-4 w-100" onChange={(e) => setInput(e)}></input>
                    </label>
                  </div>
                  <div className="col-lg-10">
                    <label className="text-white font-Spinnaker">
                      Insira uma nova senha
                      <input name="newPassword" className="mb-4 w-100" onChange={(e) => setInput(e)}></input>
                    </label>
                  </div>
                  <div className="col-lg-10">
                    <label className="text-white font-Spinnaker">
                      Confirmar nova senha
                      <input name="confNewPassword" className="mb-4 w-100" onChange={(e) => setInput(e)}></input>
                    </label>
                  </div>
                </div>
                <div className="col-lg-10 p-0">
                  {
                    !!errorMessage && (
                      <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-3 px-4 my-3 rounded ">
                        <p className="bg-danger rounded-circle text-white text-center align-self-center mb-0" style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75">
                          {errorMessage}
                        </p>
                      </div>
                    )
                  }
                  {
                    !!successMessage && (
                      <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-08 m-0">
                          {successMessage}
                        </p>
                      </div>
                    )
                  }
                </div>
                <div className="col-lg-10 p-0">
                  <button
                    type="button"
                    className="text-white btn-entrar btn w-100 py-2 mb-3 font-Spinnaker"
                    onClick={() => confirmChangePassword()}
                  >Confirmar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default TrocarSenha
