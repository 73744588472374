import React, { useEffect, useState } from 'react'
import { isBrowser, getUser, changeAttributes } from '../../services/auth'
import { Link } from 'gatsby'
// Components
import ColetaOleo from './components/coleta-de-oleo'
import GestaoResiduos from './components/gestao-de-residuos'

// Assets
import iconeCaminhao from '../../images/solicite-sua-coleta/tank-truck.svg'

// Util
import CentroColetaJson from '../../utils/centros.json'
import InputMask from 'react-input-mask'

const DadosCadastrais = () => {
  const [formState, setFormState] = useState()
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [enableField, setEnableField] = useState(true)
  const [ufs, setUfs] = useState([])
  const [districts, setDistricts] = useState([])
  const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
  const [cidade, setCidade] = useState()

  const user = getUser().attributes
  user.address = user.address.split(' - ')
  user['custom:cnpj'] = user['custom:cnpj'].replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  user.phone_number = user.phone_number.replace(/^(\+\d{2})(\d{2})(\d{5})(\d{4})/, '$2 $3-$4')
  useEffect(() => {
    const keys = Object.keys(CentroColetaJson)
    console.log(keys)
    setUfs(keys.map(key => ({ concat: CentroColetaJson[key].concat, id: key })))
    setDistricts(CentroColetaJson[user.address[1]].cidade)
    setCidade(user.address[0])
  }, [])

  const submit = () => {
    handleFields()
    changeAttributes(formState)
      .then(res => {
        if (res === 200) {
          setEnableField(true)
          setSuccess('Dados Alterados com sucesso!')
          setTimeout(() => {
            isBrowser() && window.location.reload()
          }, 2000)
        } else {
          setError('Não foi possível alterar dados, tente novamente!')
        }
      })
  }

  const handleFields = () => {
    const userHandler = getUser().attributes
    console.log(formState.phone_number)
    const cidade = formState.cidade !== undefined ? formState.cidade : user.address[0]
    const estado = formState.estado !== undefined ? formState.estado : user.address[1]
    formState.phone_number = formState.phone_number !== undefined ? ('+55' + formState.phone_number.replace(/\s|-|\(|\)|/gi, '')) : userHandler.phone_number
    console.log(formState)
    delete formState.cidade
    delete formState.estado
    formState.address = cidade + ' - ' + estado
  }

  const setInput = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  function handleStateSelected (e) {
    console.log(e)
    const center = CentroColetaJson[e.target.value]
    setDistricts(center.cidade)
    setCidade('-- Selecione --')
    setFormState({ ...formState, estado: e.target.value })
  }

  return (
    <>
      <div className="container pr-lg-5 pl-lg-5 pt-5 pb-2 pr-4 pl-4 mb-5" id="dadosCadastrais">
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            { console.log(formState) }
            <h4 className="text-lwart-blue mb-0 d-flex align-items-center"><b className="font-Spinnaker">Dados Cadastrais</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao"/>
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />
        <div className="row">
          <div className="col-lg-3">
            <label className="mb-0 font-size-08 text-muted">
              Estado
            </label>

            <select
              disabled={enableField}
              className="inputColeta w-100"
              name="estado"
              onChange={(event) => handleStateSelected(event)}
            >
              <option defaultValue> { user.address[1] }</option>
              {
                ufs.map((uf, index) => {
                  return (
                    <option value={uf.id} key={uf.id}>
                      {uf.concat}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <div className="col-lg-3">
            <label className="mb-0 font-size-08 text-muted">
              Cidade
            </label>

            <select
              className="inputColeta w-100"
              name="cidade"
              onChange={(event) => setInput(event)}
              disabled={enableField}
            >
              <option defaultValue>{ cidade }</option>
              {
                districts.map((district, index) => {
                  const name = Object.keys(district)[0]
                  return (
                    <option value={name} key={index}>
                      {name}
                    </option>
                  )
                })
              }
            </select>

          </div>
          <div className="col-lg-3">
            <label className="w-100 font-size-08 text-muted">
              CNPJ
              <input
                type="text"
                disabled
                name="custom:cnpj"
                onChange={(e) => setInput(e)}
                defaultValue={user['custom:cnpj']}
                className="w-100 mb-3 inputColeta" />
            </label>
          </div>
          <div className="col-lg-3">
            <label className="w-100 font-size-08 text-muted">
              Telefone
              <InputMask
                mask={telMask}
                maskPlaceholder={null}
                className="mb-3 w-100 inputColeta"
                name="phone_number"
                type="text"
                defaultValue={user.phone_number}
                onChange={(e) => {
                  setInput(e)
                  if (e.target.value.match(/\d{2}\s\d{4}-\d{5}/)) {
                    setTelMask([/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])
                  } else {
                    setTelMask([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
                  }
                }}
              />
            </label>
          </div>
          <div className="col-lg-6">
            <label className="w-100 font-size-08 text-muted">
              Nome
              <input
                type="text"
                disabled={enableField}
                name="name"
                onChange={(e) => setInput(e)}
                defaultValue={user.name}
                className="w-100 mb-3 inputColeta" />
            </label>
          </div>
          <div className="col-lg-6">
            <label className="w-100 font-size-08 text-muted">
              Email
              <input
                type="text"
                disabled
                name="email"
                onChange={(e) => setInput(e)}
                defaultValue={user.email}
                className="w-100 mb-3 inputColeta" />
            </label>
          </div>
          <div className="offset-6"/>
          <div className="col-lg-6 d-flex justify-content-between">
            <button
              onClick={enableField ? () => setEnableField(false) : () => submit() }
              className="text-white bg-lwart-blue btn w-50 mr-2 py-2 mb-3 font-Spinnaker"
            >
              {enableField ? 'Alterar Dados' : 'Confirmar'}
            </button>
            <Link to="../alterar-senha/" className="text-white bg-lwart-green3 btn w-50 py-2 mb-3 font-Spinnaker">Alterar Senha</Link>
          </div>
          <div className="col-12">
            {
              !!success && (
                <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                  <p
                    className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                    style={{ width: '45px', height: '45px' }} >✔</p>
                  <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                    {success}
                  </p>
                </div>
              )
            }
            {
              !!error && (
                <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-2 px-4 my-3 rounded">
                  <p className="bg-danger rounded-circle text-white text-center align-self-center mb-0 mr-3" style={{ width: '25px', height: '25px' }} >!</p>
                  <p className="text-center text-white mb-0 font-weight-bold w-75">
                    {error}
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

const PesquisaSatisfacao = () => {
  const prevRoute = isBrowser() && window.localStorage.getItem('prevRoute')

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('dadosCadastrais')
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
  }, [])

  return (
    <div>
      {console.log('prev', prevRoute)}
      { prevRoute === 'coleta-de-oleo' ? <ColetaOleo /> : <GestaoResiduos /> }
      <DadosCadastrais />
    </div>
  )
}

export default PesquisaSatisfacao
