import { API } from 'aws-amplify'
const apiName = 'PesquisaAPI' // replace this with your api name.
const path = '/pesquisa' // replace this with the path you have configured on your API

export async function putItem (data) {
  data.cnpj = data.cnpj.replace(/\.|\/|-/gi, '')
  data.data = new Date().toISOString()
  const myPost = {
    body: {
      ...data
    }
  }
  console.log(myPost)
  try {
    await API.post(apiName, path, myPost)
    console.log('post success')
    return 200
  } catch (err) {
    console.log('err resp', err)
    return err
  }
}

export async function getItems (cnpj) {
  cnpj = cnpj.replace(/\.|\/|-/gi, '')
  const pathGet = path + '?cnpj=' + cnpj
  try {
    const response = await API.get(apiName, pathGet, {})
    return response
  } catch (err) {
    console.log('err resp', err)
  }
}
