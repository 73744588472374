import React, { useEffect, useState } from 'react'
import { isBrowser, getUser } from '../../services/auth'
import { getItems } from '../../services/api'

// Components
import ColetaOleo from './components/coleta-de-oleo'
import GestaoResiduos from './components/gestao-de-residuos'

// Assets
import iconeCaminhao from '../../images/solicite-sua-coleta/tank-truck.svg'

const HistoryDesktop = (props) => {
  return (
    <>
      <div className="container pr-lg-5 pl-lg-5 pt-5 pb-2 pr-4 pl-4 mb-5 d-md-block d-none" id="historico-coleta">
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            <h4 className="text-lwart-blue mb-0 d-flex align-items-center"><b className="font-Spinnaker">Histórico de Coleta</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao"/>
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />
        <div className="table-licencas">
          <table className="w-100">
            <tr>
              <th className="px-3 text-center font-Spinnaker">#</th>
              <th className="px-3 text-center font-Spinnaker">Data</th>
              <th className="px-3 text-center font-Spinnaker">CNPJ</th>
              <th className="px-3 text-center font-Spinnaker">Responsável</th>
              <th className="px-3 text-center font-Spinnaker">Litros / Tipo Resíduo</th>
            </tr>
            <tbody>
              { props.spin && (
                <td className="py-5" colSpan="5">
                  <div className="pure-spinner mx-auto"/>
                </td>
              )}
              { !props.spin && props.history.length === 0 ? (
                <>
                  <td className="h4 text-center py-4" colSpan="5" >Não há solicitações de coleta</td>
                </>
              ) : (
                props.history.map((hist, index) => {
                  hist[0] = index
                  return (
                    <tr key={index}>
                      {
                        hist.map((hist, index) => {
                          return (
                            <td key={index} className="py-2 px-3 text-center font-Spinnaker">
                              {hist}
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })

              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const HistoryMobile = (props) => {
  console.log(props)
  return (
    <>
      <div className="container pr-lg-5 pl-lg-5 pt-5 pb-2 pr-4 pl-4 mb-5 d-md-none d-block" >
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            <h4 className="text-lwart-blue mb-0 d-flex align-items-center"><b className="font-Spinnaker">Histórico de Coleta</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao"/>
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />
        <div>
          {props.history.map(hist => {
            return (
              hist[4].match(/\d+/) ? (
                <>
                  <div className="card mb-3 border-left-coleta">
                    <div className="card-body">
                      <div className="card-header bg-transparent border-0 p-0">
                        <h4 className="card-title">Coleta de Óleo</h4>
                      </div>
                      <div className="row row-cols-lg-2 row-cols-1">
                        <div className="col">
                          <p className="mb-0 "><span className="text-muted font-weight-bold">Nome:</span> {hist[3]}</p>
                        </div>
                        <div className="col">
                          <p className="mb-0 "><span className="text-muted font-weight-bold">CNPJ:</span> {hist[2]}</p>
                        </div>
                        <div className="col">
                          <p className="mb-0 "><span className="text-muted font-weight-bold">Data:</span> {hist[1]}</p>
                        </div>
                        <div className="col">
                          <p className="mb-0 "><span className="text-muted font-weight-bold">Litros</span> {hist[4]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card mb-3 border-left-residuos">
                  <div className="card-body">
                    <div className="card-header bg-transparent border-0 p-0">
                      <h4 className="card-title">Gestão de Resíduos</h4>
                    </div>
                    <div className="row row-cols-lg-2 row-cols-1">
                      <div className="col">
                        <p className="mb-0 "><span className="text-muted font-weight-bold">Nome:</span> {hist[3]}</p>
                      </div>
                      <div className="col">
                        <p className="mb-0 "><span className="text-muted font-weight-bold">CNPJ:</span> {hist[2]}</p>
                      </div>
                      <div className="col">
                        <p className="mb-0 "><span className="text-muted font-weight-bold">Data:</span> {hist[1]}</p>
                      </div>
                      <div className="col-12 border-left p-1 ml-3 rounded">
                        <p className="m-0"><span className="text-muted font-weight-bold">Tipo de Resíduo:</span></p>
                        <p className="mb-0 pl-3">{hist[4]}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          })}
        </div>
      </div>
    </>
  )
}

const PesquisaSatisfacao = () => {
  const prevRoute = isBrowser() && window.localStorage.getItem('prevRoute')
  const [spin, setSpin] = useState(true)

  const [history, setHistory] = useState([])
  const user = getUser().attributes

  useEffect(() => {
    handleScroll()
    getItems(user['custom:cnpj'])
      .then(res => {
        const arr = res.map(h => {
          const rows = []
          rows[1] = h.data.split('T')[0].replace(/^(\d{4})-(\d{2})-(\d{2})/gi, '$3/$2/$1')
          rows[2] = h.cnpjUnidade.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
          rows[3] = h.nome
          rows[4] = h.servico === 'coleta-de-oleo' ? h.qtdLitros : h.tipoResiduo
          return rows
        })
        setHistory(arr)
        setSpin(false)
      })
  }, [])

  const handleScroll = () => {
    setTimeout(() => {
      const el = document.getElementById('historico-coleta')
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
  }
  return (
    <div>
      { prevRoute === 'coleta-de-oleo' ? <ColetaOleo /> : <GestaoResiduos /> }
      <HistoryDesktop history={history} spin={spin} />
      <HistoryMobile history={history} spin={spin} />
    </div>
  )
}

export default PesquisaSatisfacao
