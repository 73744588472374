/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { isBrowser, getUser } from '../../services/auth'
import { putItem } from '../../services/api-pesquisa'
// Components
import ColetaOleo from './components/coleta-de-oleo'
import GestaoResiduos from './components/gestao-de-residuos'

// Assets
import iconeCaminhao from '../../images/solicite-sua-coleta/tank-truck.svg'

const PesquisaForm = () => {
  const [formState, setFormState] = useState({})
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [outro1, setOutro1] = useState(false)
  const [outro2, setOutro2] = useState(false)

  const setInput = (e) => {
    console.log(e.target.value)
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    console.log(formState, Object.keys(formState).length)
    if (Object.keys(formState).length === 12) {
      const cnpj = getUser().attributes['custom:cnpj']
      formState.cnpj = cnpj
      putItem(formState).then(
        res => {
          res === 200
            ? setSuccessMessage(<>Pesquisa Enviada com sucesso! <br/> Obrigado por colaborar com a nossa Pesquisa!</>)
            : setErrorMessage(<>Não Foi Possível completar a pesquisa, tente novamente!</>)
        }
      )
    } else {
      setErrorMessage(<>Preencha todos os campos da Pesquisa!</>)
    }
  }

  return (
    <>
      <div className="container pr-lg-5 pl-lg-5 pt-5 pb-5 pr-4 pl-4" id="pesquisa">
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            <h4 className="text-lwart-blue mb-0 d-flex align-items-center"><b className="font-Spinnaker">Pesquisa de Satisfação</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao"/>
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />

        <form className="formPesquisa">
          <label>1) A frequência da coleta está adequada?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Sim" name="frequenciaAdequada" value="Sim" /><label htmlFor="Sim"> Sim </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Nao" name="frequenciaAdequada" value="Nao"/><label htmlFor="Nao"> Não </label>
            </div>
          </div>

          <label>2) Quem é o responsável pela destinação do Óleo Lubrificante Usado em seu estabelecimento?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input
                onClick={(e) => {
                  setInput(e)
                  setOutro1(false)
                }}
                type="radio"
                id="Proprietario"
                name="responsavelDestinacao"
                value="Proprietario"/>
              <label htmlFor="Proprietario"> Proprietário </label>
            </div>
            <div className="col-lg-auto">
              <input
                onClick={(e) => {
                  setInput(e)
                  setOutro1(false)
                }}
                type="radio"
                id="Gerente"
                name="responsavelDestinacao"
                value="Gerente"/>
              <label htmlFor="Gerente"> Gerente </label>
            </div>
            <div className="col-lg-auto">
              <input
                onClick={(e) => {
                  setInput(e)
                  setOutro1(false)
                }}
                type="radio"
                id="Frentista"
                name="responsavelDestinacao"
                value="Frentista"/>
              <label htmlFor="Frentista"> Frentista </label>
            </div>
            <div className="col-lg-auto">
              <input
                onClick={(e) => {
                  setInput(e)
                  setOutro1(false)
                }}
                type="radio"
                id="Responsavel_pela_troca_de_oleo"
                name="responsavelDestinacao"
                value="Responsavel pela troca de oleo"/>
              <label htmlFor="Responsavel_pela_troca_de_oleo"> Responsável pela troca de Óleo </label>
            </div>
            <div className="col-12 d-lg-flex align-items-center mt-3">
              <input
                onClick={(e) => {
                  setInput(e)
                  setOutro1(true)
                }}
                type="radio"
                id="Outro"
                name="responsavelDestinacao"
                value="Outro"/>
              <label htmlFor="Outro"> Outro </label>
              <input
                onChange={(e) => setInput(e)}
                type="text"
                className="inputPesquisa"

                disabled={formState ? outro1 !== true : true}
                name="responsavelDestinacao" />
            </div>
          </div>

          <label>3) Qual a forma de contato você utiliza atualmente para solicitar a coleta com a Lwart?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Disque_coleta" name="formaContato" value="Disque_coleta"/><label htmlFor="Disque_coleta"> Disque Coleta (0800) </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Celular_coletor" name="formaContato" value="Celular_coletor"/><label htmlFor="Celular_coletor"> Celular do coletor </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Ligacao" name="formaContato" value="Ligacao"/><label htmlFor="Ligacao"> Ligação direta para o centro de coleta da sua região </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Site" name="formaContato" value="Site"/><label htmlFor="Site"> Site/Fale conosco </label>
            </div>
            <div className="col-12 d-lg-flex align-items-center mt-3">
              <label htmlFor="Melhor_forma" className="text-nowrap mb-0"> Qual é a melhor forma para você? </label>
              <input onChange={(e) => setInput(e)} type="text" className="inputPesquisa" name="formaContato" />
            </div>
          </div>

          <label>4) De acordo com sua resposta anterior, como você avalia o atendimento da Lwart em suas solicitações de coleta</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Otimo4" name="avaliacaoAtendimento" value="Otimo"/><label htmlFor="Otimo4"> Ótimo </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Bom4" name="avaliacaoAtendimento" value="Bom"/><label htmlFor="Bom4"> Bom </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Regular4" name="avaliacaoAtendimento" value="Regular"/><label htmlFor="Regular4"> Regular </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Ruim4" name="avaliacaoAtendimento" value="Ruim"/><label htmlFor="Ruim4"> Ruim </label>
            </div>
            <div className="col-12 d-lg-flex align-items-center mt-3">
              <label htmlFor="Por_que4" className="text-nowrap m-0"> Por quê? </label>
              <input onChange={(e) => setInput(e)} type="text" className="inputPesquisa" name="avaliacaoAtendimentoPorque" />
            </div>
          </div>

          <label>5) Sobre o atendimento do coletor</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Otimo5" name="avaliacaoAtendimentoColetor" value="Otimo"/><label htmlFor="Otimo5"> Ótimo </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Bom5" name="avaliacaoAtendimentoColetor" value="Bom"/><label htmlFor="Bom5"> Bom </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Regular5" name="avaliacaoAtendimentoColetor" value="Regular"/><label htmlFor="Regular5"> Regular </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Ruim5" name="avaliacaoAtendimentoColetor" value="Ruim"/><label htmlFor="Ruim5"> Ruim </label>
            </div>
            <div className="col-12 d-lg-flex align-items-center mt-3">
              <label htmlFor="Por_que5" className="text-nowrap m-0"> Por quê? </label>
              <input onChange={(e) => setInput(e)} type="text" className="inputPesquisa" name="avaliacaoAtendimentoColetorPorque" />
            </div>
          </div>

          <label>6) O colaborador da Lwart entrega a documentação necessária (CCO)?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Sempre" name="documentacaoPresente" value="Sempre"/><label htmlFor="Sempre"> Sempre </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="As_vezes" name="documentacaoPresente" value="As_vezes"/><label htmlFor="As_vezes"> Às vezes </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Raramente" name="documentacaoPresente" value="Raramente"/><label htmlFor="Raramente"> Raramente </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Nunca" name="documentacaoPresente" value="Nunca"/><label htmlFor="Nunca"> Nunca </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Nao_sei_informar" name="documentacaoPresente" value="Nao_sei_informar"/><label htmlFor="Nao_sei_informar"> Não sei informar </label>
            </div>
          </div>

          <label>7) Como você tomou conhecimento sobre os serviços da Lwart?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => {
                setInput(e)
                setOutro2(false)
              }} type="radio" id="Indicacao_amigos" name="conhecimentoServicos" value="Indicacao amigos"/><label htmlFor="Indicacao_amigos"> Indicação de amigos </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => {
                setInput(e)
                setOutro2(false)
              }} type="radio" id="Site_Redes" name="conhecimentoServicos" value="Site/Redes Sociais"/><label htmlFor="Site_Redes"> Site/redes sociais </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => {
                setInput(e)
                setOutro2(false)
              }} type="radio" id="Anuncios" name="conhecimentoServicos" value="Anuncios"/><label htmlFor="Anuncios"> Anúncios </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => {
                setInput(e)
                setOutro2(false)
              }} type="radio" id="Caminhoes" name="conhecimentoServicos" value="Caminhoes"/><label htmlFor="Caminhoes"> Caminhões </label>
            </div>
            <div className="col-12 d-lg-flex align-items-center mt-3">
              <input onClick={(e) => {
                setInput(e)
                setOutro2(true)
              }} type="radio" id="Outro2" name="conhecimentoServicos" value="Outro2"/>
              <label htmlFor="Outro2"> Outro </label>
              <input
                onChange={(e) => setInput(e)}
                type="text"
                className="inputPesquisa"
                name="conhecimentoServicos"
                disabled={formState ? outro2 !== true : true}
              />
            </div>
          </div>

          <label>8) O que você considera mais importante no momento da escolha da empesa que destinará o óleo usado?</label>
          <div className="row row-cols-lg row-cols-1 mb-4 align-items-center">
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Garantia_legislacao" name="escolhaDaEmpresa" value="Garantia de que a legislação está sendo atendida"/>
              <label htmlFor="Garantia_legislacao"> Garantia de que a legislação está sendo atendida </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Atendimento" name="escolhaDaEmpresa" value="Atendimento"/>
              <label htmlFor="Atendimento"> Atendimento </label>
            </div>
            <div className="col-lg-auto">
              <input onClick={(e) => setInput(e)} type="radio" id="Condicoes_comerciais" name="escolhaDaEmpresa" value="Condicoes Comerciais"/>
              <label htmlFor="Condicoes_comerciais"> Condições Comerciais </label>
            </div>
          </div>

          <label>9) Qual a chance de você indicar a Lwart para um amigo?</label>
          <div className="d-lg-flex d-none justify-content-between">
            <p>Nada provável</p>
            <p>Muito provável</p>
          </div>
          <div className="row row-cols-lg-11 row-cols-6 mb-5 align-items-center w-100">
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="0" name="chanceRecomendacao" value="0"/>
              <label htmlFor="0"> 0 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="1" name="chanceRecomendacao" value="1"/>
              <label htmlFor="1"> 1 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="2" name="chanceRecomendacao" value="2"/>
              <label htmlFor="2"> 2 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="3" name="chanceRecomendacao" value="3"/>
              <label htmlFor="3"> 3 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="4" name="chanceRecomendacao" value="4"/>
              <label htmlFor="4"> 4 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="5" name="chanceRecomendacao" value="5"/>
              <label htmlFor="5"> 5 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="6" name="chanceRecomendacao" value="6"/>
              <label htmlFor="6"> 6 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="7" name="chanceRecomendacao" value="7"/>
              <label htmlFor="7"> 7 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="8" name="chanceRecomendacao" value="8"/>
              <label htmlFor="8"> 8 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="9" name="chanceRecomendacao" value="9"/>
              <label htmlFor="9"> 9 </label>
            </div>
            <div className="col-lg">
              <input onClick={(e) => setInput(e)} type="radio" id="10" name="chanceRecomendacao" value="10"/>
              <label htmlFor="10"> 10 </label>
            </div>
          </div>

          <label>10) Algum comentário ou sugestão que gostaria de fazer:</label>
          <div className="row row-cols-1 mb-4 align-items-center">
            <div className="col">
              <textarea className="w-100 inputPesquisa" rows="5" name="sugestao" onChange={(e) => setInput(e)} />
            </div>
            {
              !!errorMessage && (
                <div className="col-lg-12">
                  <div className="d-flex bg-lwart-darkblue align-items-center justify-content-center py-2 px-4 my-3 rounded">
                    <p className="bg-danger shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                      style={{ width: '25px', height: '25px' }} >!</p>
                    <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              )
            }
            {
              !!successMessage && (
                <div className="col-md-12">
                  <div className="d-flex align-items-center justify-content-center bg-lwart-green rounded p-2 my-3">
                    <p
                      className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-text-center"
                      style={{ width: '45px', height: '45px' }} >✔</p>
                    <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                      {successMessage}
                    </p>
                  </div>
                </div>
              )
            }
          </div>
          {
            !successMessage && (
              <div className="row">
                <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-buttonGreen3 w-100 text-white font-Spinnaker text-white"
                    onClick={() => handleSubmit()}
                  >Confirmar</button>
                </div>
              </div>
            )}
        </form>
      </div>
    </>
  )
}

const PesquisaSatisfacao = () => {
  console.log(location)
  const prevRoute = isBrowser() && window.localStorage.getItem('prevRoute')

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('pesquisa')
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
  }, [])

  return (
    <div>
      { prevRoute === 'coleta-de-oleo' ? <ColetaOleo /> : <GestaoResiduos /> }
      <PesquisaForm />
    </div>
  )
}

export default PesquisaSatisfacao
