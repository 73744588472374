/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getUser, isBrowser } from '../../services/auth'

// Assets
import pin from '../../images/solicite-sua-coleta/003-pin.svg'
import iconePrinter from '../../images/solicite-sua-coleta/002-printer.svg'
import setabaixarBranco from '../../images/solicite-sua-coleta/001-down-arrow-br.svg'
import iconeCaminhao from '../../images/solicite-sua-coleta/tank-truck.svg'

// Components
import ColetaOleo from './components/coleta-de-oleo'
import GestaoResiduos from './components/gestao-de-residuos'

// scss
import './coleta.scss'

// Util
import centroColetaJSON from '../../utils/centros.json'
import slugify from 'slugify'

const CollectionCenter = (data) => {
  const [currentCenter, setCurrentCenter] = useState({ localidade: '', uf: '' })
  useEffect(() => {
    useCurrentLocation()
  }, [])

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  function successCallback (position) {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    calcDistance(pos)
  }

  function errorCallback (error) {
    // Check for known errors
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCurrentCenter(data.nodes.filter(center => center.localidade === 'Matriz')[0])
        initMaps(data.nodes.filter(center => center.localidade === 'Matriz')[0])
        break
      case error.POSITION_UNAVAILABLE:
        console.error('Data from location services is ' +
          'currently unavailable.')
        break
      case error.TIMEOUT:
        console.error('Location could not be determined ' +
          'within a specified timeout period.')
        break
      default:
        break
    }
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    let center = data.nodes.map(node => {
      const LatCentro = node.mapa.lat
      const LngCentro = node.mapa.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatCentro) / 180

      const theta = myLng - LngCentro
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })
    center = center.sort((a, b) => (a.distance - b.distance))
    setCurrentCenter(center[0])
    initMaps(center[0])
  }

  const initMaps = (currentCenter) => {
    if (isBrowser()) {
      if (!window.google) {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.MAPS_KEY}`
        const insercao = document.getElementsByTagName('script')[0]
        insercao.parentNode.insertBefore(script, insercao)
        script.addEventListener('load', e => {
          console.log('Geocoder is now available!')
          currentCenter && createCenterMarker(currentCenter)
        })
      } else {
        currentCenter && createCenterMarker(currentCenter)
      }
    }
  }

  const createCenterMarker = (center) => {
    if (window.google) {
      const map = new window.google.maps.Map(document.getElementById('mapa'), {
        center: center.mapa,
        zoom: 17,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      })

      var marker = new window.google.maps.Marker({
        position: center.mapa,
        map: map,
        title: 'Centro de Coleta'
      })
    }
  }

  return (
    <>
      <div id="maps-section" className={'container pr--lg5 pl-lg-5 pt-2 pb-2 pr-4 pl-4'}>
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            <h4 className="text-lwart-blue mb-0 d-flex align-items-center"><b className="font-Spinnaker">Seu Centro de Coleta</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao" />
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />
        <img src={pin} alt="Pin" className="float-left" />
        <p className="ml-5">
          {currentCenter
            ? <>
              <b className="font-size-1125">{currentCenter.localidade} - {currentCenter.uf}</b><br />
              {currentCenter.logradouro} - {currentCenter.bairro} - {currentCenter.cep}<br />
              Tel: {currentCenter.telefone} <br />
              E-mail: {currentCenter.email}
            </>
            : <b className="font-size-1125">-</b>}
        </p>
        <div className="col-12 pr-0 pl-0">
          <div style={{ position: 'relative' }}>
            <div id='mapa' style={{ width: '100%', height: '35vh' }} />
          </div>
        </div>
      </div>
    </>
  )
}

const YourLicences = (data) => {
  const [filtroTipo, setFiltroTipo] = useState('matriz')
  const user = getUser()
  const UF = user.attributes.address.split(' - ')[1]
  const [ufs, setUfs] = useState([])
  const sortValues = (object, field) => (object.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)))
  useEffect(() => {
    fetch(`${process.env.IBGE}/estados`)
      .then(res => res.json())
      .then(res => setUfs(sortValues(res, 'sigla')))
  }, [])

  function handleStateSelected (event) {
    data.estadoAlterado(event)
  }

  const { filter } = data
  const Licences = props => (
    <tr key={data.key}>
      <td>
        <div className="w-100 rowpdfs">
          <div className="row justify-content-betwween py-2 px-4">
            <div className="col-lg-7 col-7 d-flex px-lg-3 p-0">
              <p className="mb-0 d-flex align-items-center">{props.title}.{props.url.match(/[^\\]*\.(\w+)$/)[1]}</p>
            </div>
            <div className="col-lg-5 col-5 px-lg-3 p-0 d-flex justify-content-end">
              <a
                href={props.url}
                target="_blank"
                rel="noreferrer"
                className="btn btn-buttonGreen3 p-2 text-white font-Spinnaker d-flex justify-content-lg-between justify-content-center"
              >
                {/* <span className="text-white  d-lg-flex d-none align-items-center">Imprimir</span> */}
                <img src={iconePrinter} alt="Impressora" />
              </a>
              <a
                href={props.url}
                download
                className="btn btn-buttonGreen3 p-2 text-white font-Spinnaker ml-3 d-flex justify-content-lg-between justify-content-center"
              >
                {/* <span className="text-white d-lg-flex d-none align-items-center">Baixar</span> */}
                <img src={setabaixarBranco} alt="Seta baixar em Branco" />
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )

  return (
    <div className={'container pr--lg5 pl-lg-5 pt-2 pb-2 pr-4 pl-4'}>
      <div className="row">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <h4 className="text-lwart-blue mb-0"><b className="font-Spinnaker">Suas Licenças</b></h4>
          <img src={iconeCaminhao} alt="icone caminhao" className="iconCaminhao" />
        </div>
        <div className="col-12">
          <div className="w-100 border-bottom-coleta" />
        </div>
        <div className="col-12">
          <p className="mt-3 mb-1">Confira abaixo as licenças e documentos de que você precisa:</p>
        </div>
        <div className="col-4">
          <select
            className="inputEstado w-100 mb-4"
            name="estado"
            onChange={(event) => handleStateSelected(event.target.value)}
          >
            <option selected disabled key={0}>Estado atual selecionado: {UF}</option>
            {
              ufs.map((uf) => {
                uf.concat = uf.sigla + ' - ' + uf.nome
                return (
                  <option value={uf.sigla} key={uf.id}>
                    {uf.concat}
                  </option>
                )
              })
            }
          </select>
        </div>
        <div className="col-4">
          <select
            className="inputEstado w-100 mb-4"
            name="categoriaLicenca"
            onChange={(event) => setFiltroTipo(event.target.value)}
          >
            <option value="matriz" key="0">
              Licenças Ambientais da Matriz
            </option>
            <option value="estado" key="1">
              Licenças Ambientais do Centro de Coleta
            </option>
          </select>
        </div>
      </div>
      {
        <div className="table-licencas">
          <div className="w-100 bg-lwart-blue py-2 px-2">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-9 col-12 d-flex align-items-center">
                <p className="font-size-1 mb-0 text-white letter-space-normal text-uppercase text-lg-left text-center ">
                  <b>{filter[filtroTipo].nome_agrupamento}</b>
                </p>
              </div>
            </div>
          </div>
          {filter[filtroTipo].licencas.length > 0
            ? (
              <table className="w-100">
                <tbody>
                  {
                    filter[filtroTipo].licencas.map((licenca, index) => {
                      return (
                        <Licences
                          key={index}
                          title={licenca.node.nomeLicenca}
                          url={licenca.node.pdf.file.url}
                        />
                      )
                    })
                  }
                </tbody>
              </table>
            )
            : (
              <>
                <p className="text-muted font-size-1125 text-center font-weight-bold mobile-conf">Não há nenhuma licença associada.</p>
              </>
            )
          }
        </div>
      }
      <div className="col-12 border-bottom-coleta mb-2 mt-5" />
      <p>Importante: Estas informações são confidenciais e destinadas a indivíduo(s)
        e propósitos específicos, sendo protegidas por lei. O sistema utiliza diversas fontes de consulta e as informações são
        atualizadas em diferentes momentos, de modo que as licenças poderá sofrer alteraçõesa qualquer momento e/ou apresentar
        alguma informação desatualizada.
      </p>
    </div>
  )
}

const Admin = (location) => {
  useEffect(() => {
    const user = getUser()
    const UF = user.attributes.address.split(' - ')[1]
    setLicencasDoEstado(filterLicencesByUF(UF))
    setLicencasDaMatriz(filterLicencesByUF('todos'))
    setLicencasTodos(data.licencas.edges.filter(licenca => (licenca.node.exibirTodos === true) && (licenca.node.pdf)))
  }, [])

  const [licencasDoEstado, setLicencasDoEstado] = useState([])
  const [licencasTodos, setLicencasTodos] = useState([])
  const [licencasDaMatriz, setLicencasDaMatriz] = useState([])

  const data = useStaticQuery(
    graphql`
      query {
        municipios: allContentfulMunicipio(filter:{node_locale: { eq: "pt-BR"}}) {
          nodes {
            uf
            centroDeColeta {
              localidade
            }
            nome
          }
        }
        licencas: allContentfulLicenca(filter: {node_locale: {eq: "pt-BR"}}, sort: {fields: order, order: ASC}) {
          edges {
            node {
              uf
              exibirTodos
              nomeLicenca
              pdf{
                file {
                  url
                }
              }
              dataDeValidade
            }
          }
        } 
        cc: allContentfulCentroDeColeta(filter:{node_locale: { eq: "pt-BR"}}) {
          nodes {
            localidade
            logradouro
            uf
            telefone
            email
            bairro
            cep
            mapa{
              lat
              lng: lon
            }
          }
        }
      }
    `
  )
  // console.log('estado ->', licencasDoEstado, 'matriz ->', licencasDaMatriz)

  const filterLicencas = data.licencas.edges.filter(licenca => {
    if (licenca.node.dataDeValidade !== null) {
      const dataValidade = new Date(licenca.node.dataDeValidade)
      const dataAtual = new Date()
      return dataValidade >= dataAtual
    } else {
      return true
    }
  })

  function handleEstado (UF) {
    setLicencasDoEstado(filterLicencesByUF(UF))
  }

  function filterLicencesByUF (UF) {
    return filterLicencas.filter(licenca => (licenca.node.uf.toLowerCase() === UF.toLowerCase()) && (licenca.node.pdf) && (licenca.node.exibirTodos === null || licenca.node.exibirTodos === false))
  }
  const filtro = {
    matriz: {
      nome_agrupamento: 'Licenças da Matriz',
      licencas: [...licencasTodos, ...licencasDaMatriz]
    },
    estado: {
      nome_agrupamento: 'Licenças do Estado',
      licencas: [...licencasTodos, ...licencasDoEstado]
    }
  }

  console.log(licencasDaMatriz)
  console.log(licencasTodos)

  return (
    <>
      {location.path.split('/')[3] === 'coleta-de-oleo' ? <ColetaOleo /> : <GestaoResiduos />}
      {location.path.split('/')[3] === 'coleta-de-oleo' ? <CollectionCenter {...data.cc} /> : ''}
      {location.path.split('/')[3] === 'coleta-de-oleo' ? <YourLicences estadoAlterado={handleEstado} filter={filtro} /> : <div className="mb-0" />}
      <div className="gradient-blue-to-green"></div>
    </>
  )
}

export default Admin
