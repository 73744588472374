import {
  validaCNPJ,
  validaEmail
} from '../utils/validation-service'
import Errors from '../utils/error-messages.json'

function validateForm (form) {
  console.log('validation', form)
  const validation = {
    canSubmit: false,
    message: ''
  }
  if (Object.values(form).some(field => field === '')) validation.message = Errors.UNFILLED_FIELDS
  if (!validaEmail(form.email)) validation.message = Errors.INVALID_EMAIL
  if (!validaCNPJ(form.cnpjUnidade)) validation.message = Errors.INVALID_CNPJ
  if (form.telefone.length < 12) validation.message = Errors.INVALID_PHONE
  if (form.nome.match(/[0-9]/gi)) validation.message = Errors.INVALID_NAME
  if (parseFloat(form.qtdLitros) <= 0) validation.message = Errors.NON_POSITIVE_LITER

  return {
    ...validation,
    canSubmit: !validation.message
  }
}

export {
  validateForm
}
