import React from 'react'
import { Router } from '@reach/router'
import Admin from '../components/admin/solicite-sua-coleta'
import PrivateRoute from '../components/privateRoute'
// import CriaConta from '../components/admin/criar-conta'
// import ConfirmaConta from '../components/admin/confirmar-conta'
// import EsqueciSenha from '../components/admin/esqueci-a-senha'
import Pesquisa from '../components/admin/pesquisa-de-satisfacao'
import DadosCadastrais from '../components/admin/dados-cadastrais'
import AlterarSenha from '../components/admin/trocar-senha'
import HistoricoColeta from '../components/admin/historico-coleta'

const AreaCliente = (data) => (
  <>
    <Router>
      <PrivateRoute path="/area-cliente/solicite-sua-coleta/coleta-de-oleo/" component={Admin} />
      <PrivateRoute path="/area-cliente/solicite-sua-coleta/gestao-de-residuos/" component={Admin} />
      <PrivateRoute path="/area-cliente/pesquisa-de-satisfacao/" component={Pesquisa} />
      <PrivateRoute path="/area-cliente/dados-cadastrais" component={DadosCadastrais} />
      <PrivateRoute path="/area-cliente/alterar-senha/" component={AlterarSenha} />
      <PrivateRoute path="/area-cliente/historico-de-coleta/" component={HistoricoColeta} />
      {/* <CriaConta path="/area-cliente/criar-conta/" />
      <ConfirmaConta path="/area-cliente/confirmar-conta/" />
      <EsqueciSenha path="/area-cliente/esqueci-a-senha/" /> */}
    </Router>
  </>
)

export default AreaCliente
